.flip-container {
  perspective: 1000px;
}

.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 300px;
  height: 380px;
}

.flipper {
  transform-style: preserve-3d;
  perspective: 1000px;
  flex: 1;
  transition: all .6s;
  position: relative;
}

.front, .back {
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0);
}

.back {
  z-index: 3;
  transform: rotateY(180deg);
}

/*# sourceMappingURL=shop.bf67a6a2.css.map */
